import { default as about_45passJJOFBuxOdgMeta } from "/codebuild/output/src3997150319/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenavi25jKPT82fdMeta } from "/codebuild/output/src3997150319/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementUVskx8LCyQMeta } from "/codebuild/output/src3997150319/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliascbcU7wNN7qMeta } from "/codebuild/output/src3997150319/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirm2thyu98wDOMeta } from "/codebuild/output/src3997150319/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasCiPO5XbFLeMeta } from "/codebuild/output/src3997150319/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editgy0S81PrydMeta } from "/codebuild/output/src3997150319/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexf9i6FqfJvEMeta } from "/codebuild/output/src3997150319/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirm6hbY7pctCyMeta } from "/codebuild/output/src3997150319/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexLZY5kHWsokMeta } from "/codebuild/output/src3997150319/src/src/pages/card/create/index.vue?macro=true";
import { default as indexjV2jzPS42rMeta } from "/codebuild/output/src3997150319/src/src/pages/card/index.vue?macro=true";
import { default as completeRmiCUveetOMeta } from "/codebuild/output/src3997150319/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirms2lA1Kv6yDMeta } from "/codebuild/output/src3997150319/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerU0aX56rKGfMeta } from "/codebuild/output/src3997150319/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactBEX6NIMxkKMeta } from "/codebuild/output/src3997150319/src/src/pages/contact.vue?macro=true";
import { default as _91id_93lVRGoJxsalMeta } from "/codebuild/output/src3997150319/src/src/pages/coupon/[id].vue?macro=true";
import { default as indexyF5A1hQfDfMeta } from "/codebuild/output/src3997150319/src/src/pages/coupon/index.vue?macro=true";
import { default as faq7hjgDrwtrOMeta } from "/codebuild/output/src3997150319/src/src/pages/faq.vue?macro=true";
import { default as indexWOMi3flA1aMeta } from "/codebuild/output/src3997150319/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93W6gMwcRtPjMeta } from "/codebuild/output/src3997150319/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexICqcirhT2FMeta } from "/codebuild/output/src3997150319/src/src/pages/index.vue?macro=true";
import { default as maintenanceYt1VpsuSJRMeta } from "/codebuild/output/src3997150319/src/src/pages/maintenance.vue?macro=true";
import { default as completeyTi2WsWBIZMeta } from "/codebuild/output/src3997150319/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmyhS03bVcD9Meta } from "/codebuild/output/src3997150319/src/src/pages/membership/confirm.vue?macro=true";
import { default as registerXhm3iLXLjUMeta } from "/codebuild/output/src3997150319/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45code89TPWLnYaJMeta } from "/codebuild/output/src3997150319/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmRJn2b3uNgaMeta } from "/codebuild/output/src3997150319/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registerFEljOBUBjwMeta } from "/codebuild/output/src3997150319/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menu6cbOgG6xQIMeta } from "/codebuild/output/src3997150319/src/src/pages/menu.vue?macro=true";
import { default as indexoOuZ5RBToAMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/index.vue?macro=true";
import { default as indexBftGTaaVsIMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as samplePXvWEDXHdeMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completetIFPnKFgzEMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeqcxczA5vmmMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexIFtdRLQ40UMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexql5WFD7UVUMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeFZZiaq7fepMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmJvPiWsoOfpMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexdVsv29ef1nMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updatejkyXf2eHYXMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirm3GVBVo42hUMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexPQd0tLgtncMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updateYLHwSBgDUAMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as complete56KYj34vUwMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updatekSZ1cX1mTbMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexRRo6x7meLVMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93CoIyEmac8BMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completetO5EHbmH7aMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmThyajgS2xvMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completejuu22bXIsJMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmzJTBgy6g5PMeta } from "/codebuild/output/src3997150319/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentga8hfu2l59Meta } from "/codebuild/output/src3997150319/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93hOJIx5Nu25Meta } from "/codebuild/output/src3997150319/src/src/pages/notice/[id].vue?macro=true";
import { default as indexTV6XGnt3hdMeta } from "/codebuild/output/src3997150319/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93jZNzya5xmHMeta } from "/codebuild/output/src3997150319/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementWBlOIo1iBcMeta } from "/codebuild/output/src3997150319/src/src/pages/pass-agreement.vue?macro=true";
import { default as completeWryCWz7rUiMeta } from "/codebuild/output/src3997150319/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeumfHdifrDkMeta } from "/codebuild/output/src3997150319/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45password3f59wrikRJMeta } from "/codebuild/output/src3997150319/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailpSEoz2Nd2UMeta } from "/codebuild/output/src3997150319/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codeNu4tF6VltMMeta } from "/codebuild/output/src3997150319/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexfrCA01u0svMeta } from "/codebuild/output/src3997150319/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codefoaE6t0yLnMeta } from "/codebuild/output/src3997150319/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexCF5tHDqo9gMeta } from "/codebuild/output/src3997150319/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialAXmHOvEA2AMeta } from "/codebuild/output/src3997150319/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliascbcU7wNN7qMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirm2thyu98wDOMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasCiPO5XbFLeMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editgy0S81PrydMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirm6hbY7pctCyMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexLZY5kHWsokMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeRmiCUveetOMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirms2lA1Kv6yDMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerU0aX56rKGfMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexyF5A1hQfDfMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexWOMi3flA1aMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexICqcirhT2FMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeyTi2WsWBIZMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmyhS03bVcD9Meta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerXhm3iLXLjUMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45code89TPWLnYaJMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmRJn2b3uNgaMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerFEljOBUBjwMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menu6cbOgG6xQIMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexoOuZ5RBToAMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexBftGTaaVsIMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: samplePXvWEDXHdeMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexRRo6x7meLVMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completetO5EHbmH7aMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmThyajgS2xvMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completejuu22bXIsJMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmzJTBgy6g5PMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentga8hfu2l59Meta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexTV6XGnt3hdMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93jZNzya5xmHMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completeWryCWz7rUiMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeumfHdifrDkMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45password3f59wrikRJMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailpSEoz2Nd2UMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codeNu4tF6VltMMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexfrCA01u0svMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codefoaE6t0yLnMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexCF5tHDqo9gMeta || {},
    component: () => import("/codebuild/output/src3997150319/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src3997150319/src/src/pages/tutorial.vue")
  }
]