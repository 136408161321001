/**
 * Flutter側から実行するNuxt側の関数をグローバルに登録する
 */
import { useUpdateChecker } from '@/composables/useUpdateChecker';
import { useMapState } from '@/composables/store/useMapStore';
import { PushRepository } from '@/features/push/api/pushRepository';
import { Location, CanGpsAccess } from '@/config/gps';
import { _Window } from '@/types/window';

export default defineNuxtPlugin(async () => {
  const router = useRouter();
  const repository = PushRepository();
  const { setCanGpsAccess, setCurrentLocation } = useMapState();
  const { checkUpdate } = useUpdateChecker();
  const { checkGPSPermission } = useGPSPermissionChecker();
  const { logEvent } = useFlutterConnection();

  /** Nuxt側でRouterバックする */
  const FGoBack = () => {
    console.log('go back from flutter');
    router.back();
  };

  /** Nuxt側で任意のページへ遷移する */
  const FToThePage = async (path: string) => {
    console.log(`go to the Page:${path} from flutter`);
    router.push(path);
  };

  /** アプリ再開時に実行する処理 */
  const FCheckUpdate = async () => {
    console.log('FCheckUpdate from flutter');
    await Promise.all([
      // アップデートが必要かチェック
      checkUpdate(),
      // 座標取得ができるかチェック
      checkGPSPermission(),
    ]);
  };

  /** Push通知開封時に実行する処理 */
  const FOpenPushNotification = async (pushId: string) => {
    console.log(`FOpenPushNotification from flutter: ${pushId}`);
    await repository.open(Number(pushId));
  };

  /** GPSの取得可否をグローバルストアに保存 */
  const FSetCanGpsAccess = (_canGpsAccess: CanGpsAccess) => {
    console.log(
      'canGpsAccess from flutter',
      `canAccess: ${_canGpsAccess.canAccess}, errorCode: ${_canGpsAccess.errorCode}`,
    );
    setCanGpsAccess(_canGpsAccess);
  };

  /** 現在の座標情報をグローバルストアに保存 */
  const FSetCurrentLocation = (_location: Location) => {
    console.log(
      'currentLocation from flutter',
      `lat: ${_location.lat}, lng: ${_location.lng}, head: ${_location.heading}`,
    );
    setCurrentLocation({
      lat: _location.lat,
      lng: _location.lng,
      heading:
        _location.heading && _location.heading > 0 ? _location.heading : 0,
    });
  };

  window.FGoBack = FGoBack;
  window.FToThePage = FToThePage;
  window.FCheckUpdate = FCheckUpdate;
  window.FOpenPushNotification = FOpenPushNotification;
  window.FSetCanGpsAccess = FSetCanGpsAccess;
  window.FSetCurrentLocation = FSetCurrentLocation;
  window.logEvent = logEvent;
});

declare const window: _Window;
