<template>
  <NuxtRouteAnnouncer />
  <NuxtErrorBoundary @error="handleError">
    <!-- アップデート要求が必要か判定する -->
    <UpdateChecker />
    <!-- 権限の判定を元に座標の取得を開始する -->
    <GPSPermissionChecker />
    <!-- ユーザーIDの更新を検知してデバイス情報を送信する -->
    <DeviceInfoRegisterer />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <VLoading :loading="!!loadings.length" fullscreen />

    <!-- GPS権限要求ダイアログ -->
    <GPSRequestDialog />
    <!-- アップデート要求ダイアログ -->
    <UpdateRequestDialog />
    <!-- エラーダイアログ -->
    <ErrorDialog />
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
  import * as Sentry from '@sentry/vue';
  import { useGtm } from '@gtm-support/vue-gtm';
  import { useLoginState } from '@/composables/store/useLoginStore';
  import { useMapState } from '@/composables/store/useMapStore';
  import { useDialogState } from '@/composables/store/useDialogStore';
  import { useLoadingState } from '@/composables/store/useLoadingStore';

  const { hook } = useNuxtApp();
  const gtm = useGtm();
  const { userId, currentVersion, setCheckedNoticeId, setPopupLastOpenedDate } =
    useLoginState();
  const {
    onWindowInitialized,
    closeSplash,
    getCheckedNoticeId,
    getPopupLastOpenedDate,
  } = useFlutterConnection();
  const { currentLocation, setFocusLocation, setParamsCurrentLocation } =
    useMapState();
  const { loadings, startLoadings, stopLoadings, clearLoadings } =
    useLoadingState();
  const { setIsOpenPopup } = useDialogState();
  const { checkGPSPermission } = useGPSPermissionChecker();
  const { isAppSupportCognitoAuth } = useCompareVersions();

  /** ページ遷移時にフルスクリーンローディングを表示 */
  hook('page:start', () => {
    startLoadings('page-loading');
  });
  hook('page:finish', () => {
    stopLoadings('page-loading');
  });
  hook('vue:error', () => {
    clearLoadings();
  });

  // NOTE: 座標取得成功後1回のみ実行
  const onceWatcher = watch(currentLocation, async () => {
    // 初回はユーザーの現在地で駐車場を検索
    setParamsCurrentLocation(currentLocation.value);
    // ユーザーの現在座標に初回だけフォーカス
    setFocusLocation(currentLocation.value);
    onceWatcher();
  });

  /** エラーをログとSentryに出力 */
  const handleError = async (error: any) => {
    console.error(error);
    Sentry.captureException(error);
    // スプラッシュ画面を閉じる
    await closeSplash();
    throw showError({
      statusCode: error.statusCode,
      message: error.message,
    });
  };

  onMounted(async () => {
    // Sentryへトランザクションを送信
    const transaction = Sentry.startTransaction({
      name: 'アプリ起動',
      op: 'アプリ起動',
    });

    try {
      const spanA = transaction.startChild({
        name: 'flutterの保存データ取得',
        op: 'flutterの保存データ取得',
      });

      // Flutterから各値を取得
      const _checkedNoticeId = (await getCheckedNoticeId()) || undefined;
      const _popupLastOpenedDate = await getPopupLastOpenedDate();

      // グローバルStateにflutterでの保存値を反映
      await Promise.all([
        setCheckedNoticeId(_checkedNoticeId),
        setPopupLastOpenedDate(_popupLastOpenedDate),
      ]);

      if (isAppSupportCognitoAuth()) await onWindowInitialized();

      spanA.finish();
    } catch (e) {
      handleError(e);
    }

    // GTMへユーザーIDを送信
    try {
      if (gtm?.enabled()) {
        window.dataLayer?.push({
          user_id: userId.value,
        });
      }
    } catch (e) {
      console.error('GTM Error', e);
    }

    // Sentryへユーザー情報を送信
    Sentry.setUser({ userId: userId.value, version: currentVersion.value });
    Sentry.setTags({ userId: userId.value, version: currentVersion.value });

    await Promise.all([
      // スプラッシュ画面を閉じる
      closeSplash(),
      // 座標取得ができるかチェック
      // TODO: Checkerコンポーネントに一任したいのでonceWatcherとともに移動したい
      checkGPSPermission(),
    ]);

    // トランザクションの終了
    transaction.finish();

    // すべて取得してからお知らせポップアップを表示
    setIsOpenPopup(true);
  });
</script>
