/**
 * https://sonicmoov.backlog.jp/view/STARTS_NAVIPARK_SMV-1539
 * HACK: fetch時に画面の描画が固まってしまう問題を解決するための処理
 * ページ開始から一定時間の間、フラグをトグルして描画を変更し続けることで画面を再描画させて解決する
 */
export const useScreenFreezeResolver = () => {
  const TOGGLE_INTERVAL = 250;
  const TOGGLE_DURATION = 5000;

  const resolverFlag = ref(true);
  const intervalId = ref<number>();

  const freezeResolver = () => {
    intervalId.value = window.setInterval(() => {
      resolverFlag.value = !resolverFlag.value;
    }, TOGGLE_INTERVAL);

    setTimeout(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
        resolverFlag.value = true;
      }
    }, TOGGLE_DURATION);
  };

  onMounted(() => {
    freezeResolver();
  });

  onUnmounted(() => {
    if (intervalId.value) clearInterval(intervalId.value);
  });

  return { resolverFlag };
};
