<template>
  <div
    v-show="loading"
    :class="
      fullscreen ? ['fixed', 'inset-0', 'z-loading', 'bg-white'] : ['relative']
    "
  >
    <div
      class="mx-auto h-16 w-16 animate-spin rounded-full border-[7px] border-y-loadingBorder2 border-l-loadingBorder1 border-r-loadingBorder2"
      :class="fullscreen ? ['absolute', 'inset-0', 'my-auto'] : ['my-14']"
    ></div>
  </div>
</template>

<script setup lang="ts">
  interface Props {
    loading: boolean;
    fullscreen?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    loading: false,
    fullscreen: false,
  });

  const { loading } = toRefs(props);
</script>
